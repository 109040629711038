import decode from 'jwt-decode'
import { socket } from './Header'

// from https://medium.com/@romanchvalbo/how-i-set-up-react-and-node-with-json-web-token-for-authentication-259ec1a90352
export default class AuthHelperMethods {
  rememberMe = false
  getToken = () => {
    if (localStorage.getItem('token')) {
      return localStorage.getItem('token')
    } else if (sessionStorage.getItem('token')) {
      return sessionStorage.getItem('token')
    } else {
      return null
    }
  }

  setToken = token => {
    if (rememberMe) {
      localStorage.setItem('token', token)
    } else {
      sessionStorage.setItem('token', token)
    }
  }

  isTokenExpired = token => decode(token).exp < Date.now() / 1000

  loggedIn = () => {
    // Check if there is a saved token and whether it is still valid
    const token = this.getToken()
    return !!token && !this.isTokenExpired(token)
  }

  // use direct logout instead
  // logout = () => {
  //   localStorage.removeItem('token')
  // }

  getDecodedToken = () => decode(this.getToken())
}

const getToken = () => {
  if (localStorage.getItem('token')) {
    return localStorage.getItem('token')
  } else if (sessionStorage.getItem('token')) {
    return sessionStorage.getItem('token')
  } else {
    return null
  }
}

const setToken = token => {
  if (rememberMe) {
    localStorage.setItem('token', token)
  } else {
    sessionStorage.setItem('token', token)
  }
}
const isTokenExpired = token => decode(token).exp < Date.now() / 1000

socket.on("updateToken", (newToken) => {
  setToken(newToken)
})

const loggedIn = () => {
  // Check if there is a saved token and whether it is still valid
  const token = getToken()
  return !!token && !isTokenExpired(token)
}

const logout = () => {
  socket.emit('personalDataLogout', getToken(), (result) => {
  })
  localStorage.removeItem('token')
  sessionStorage.removeItem('token')
  localStorage.removeItem('_hoursPercentage')
}

let rememberMe = false

const setRememberMe = (value) => rememberMe = value

const getDecodedToken = () => decode(getToken())

const getLoggedInUserId = () => getDecodedToken().id

const hasPermission = permission => getDecodedToken().permissions.includes(permission)

export { setRememberMe, getToken, setToken, isTokenExpired,
  loggedIn, logout, getDecodedToken, getLoggedInUserId, hasPermission }
