import React from 'react'
import { Card, Form } from 'react-bootstrap'
import AuthHelperMethods, { loggedIn, setRememberMe, setToken } from '../components/AuthHelperMethods'
import decode from 'jwt-decode'
import { socket } from '../components/Header'
import Button from '@mui/material/Button'
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material'
import CheckPasswordPolicy from '../utils'
import { withTranslation } from 'react-i18next'
import { HelpIcon } from './helpIcon'
import { PersonalDataContext } from './PersonalDataContext'

class Login extends React.Component {
  static contextType = PersonalDataContext
  Auth = new AuthHelperMethods()
  constructor(props) {
    super(props)

    this.validateForm = this.validateForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = { checked: false }
    this.updateCheckbox = this.updateCheckbox.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      email: "",
      password: "",
      warning: '',
      rememberMe: true,
      requiresPasswordUpdate: false,
      token: null,
    }
  }

  redirect = () => {
    setRememberMe(this.state.rememberMe)
    setToken(this.state.token)
    this.Auth.rememberMe = this.state.rememberMe
    if (loggedIn()) {
      this.context.onLogin()
      this.props.forwardFunction()
    } else {
      this.setState({
        warning: 'Invalid certificate'
      })
    }
  }

  requiresPasswordUpdate = () => {
    socket.emit('requiresPasswordUpdate', this.state.token, decode(this.state.token).id, (answer) => {
      this.setState({ requiresPasswordUpdate: answer.requiresPasswordUpdate })
      if (! answer.requiresPasswordUpdate) {this.redirect()}
    })
  }
  onClose = (formSubmitEvent) => {
    formSubmitEvent.preventDefault()
    socket.emit('passwordUpdate', this.state.token, this.state.oldPassword, this.state.newPassword, answer => {
      if (answer.success) {
        this.setState({ bool: false })
        socket.emit('passwordUpdateRequestFullfilled', this.state.token, decode(this.state.token).id)
        alert(this.props.t("PasswordChangeSuccess"))
        this.redirect()
      } else {
        alert(this.props.t("PasswordChangeError"))
        console.error(answer)
      }
    })

  }
  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  serverLogin(email, password, rememberMe) {
    socket.emit("login", email, password, rememberMe, this.authenticate)
  }

  authenticate = (result) => {
    if (! result.success) {
      this.setState({
        warning: result.err,
      })
    } else {
      // Set token and redirect to the next page
      this.setState({token: result.token})
      this.requiresPasswordUpdate()
    }
  }

  validateForm() {
    return this.state.email.length > 8 && this.state.password.length >= 8
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  updateCheckbox(checked) {
    this.setState({
      rememberMe: checked,
    })
  }

  handleSubmit = (event) => {

    event.preventDefault()

    this.serverLogin(this.state.email, this.state.password, this.state.rememberMe)
  }

  render() {

    return (
      <div className="Login">
        <div className="d-flex justify-content-center">
          <Card style={{ width: '58rem' }}>
            <Card.Header className="bg-acsplus"><h2>Login</h2></Card.Header>
            <Card.Body>
              <div className="spacer-15"></div>
              <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="email">
                  <Form.Label>{this.props.t("email")}</Form.Label>
                  <Form.Control
                    autoFocus
                    type="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="E-Mail" />
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>{this.props.t("password")}</Form.Label>
                  <Form.Control
                    value={this.state.password}
                    onChange={this.handleChange}
                    type="password"
                    placeholder="Passwort" />
                </Form.Group>
                <Form.Group controlId="formBasicChecbox">
                  <Form.Check
                    type="checkbox"
                    checked={this.state.rememberMe}
                    onChange={(event) => {
                      this.updateCheckbox(event.target.checked)
                    }}
                    label={<>
                      <>Remember me</>
                      <HelpIcon message={this.props.t('RememberMeDescription')} sx={{mb: 0}} />
                    </>}
                  />
                </Form.Group>
                { this.state.warning }
                <div className="spacer-15"></div>
                <Button disabled={!this.validateForm()} variant="contained"
                  color="primary" type="submit"
                  onClick={this.handleSubmit}>
                  Login
                </Button>
                <div className="spacer-30"></div>
              </Form>
            </Card.Body>
          </Card>
        </div>
        <Dialog open={this.state.requiresPasswordUpdate}>
          <DialogTitle>{this.props.t("ChangePassword")}</DialogTitle>
          <form onSubmit={this.onClose.bind(this)}>
            <DialogContent>
              {this.props.t("Password7Char")}
              <Form.Group
                controlId="oldPassword"
                onChange={(event) => this.onChange(event)}
              >
                <Form.Control
                  rows="1"
                  placeholder= {this.props.t("OldPassword")}
                  type="password"
                  required={true}
                />
              </Form.Group>
              <Form.Group
                controlId="newPassword"
                onChange={(event) => this.onChange(event)}
              >
                <Form.Control
                  rows="1"
                  placeholder= {this.props.t("NewPassword")}
                  type="password"
                  required
                />
              </Form.Group>
              <Form.Group
                controlId="repeatPassword"
                onChange={(event) => this.onChange(event)}
              >
                <Form.Control
                  rows="1"
                  placeholder= {this.props.t("RepeatNewPassword")}
                  type="password"
                  required
                />
              </Form.Group>
              {this.state.oldPassword === this.state.newPassword ?
                this.props.t("PWDMustDiffer") :
                this.props.t(CheckPasswordPolicy(this.state.newPassword, this.state.repeatPassword, ""))}
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                autoFocus
                color="primary"
                disabled={CheckPasswordPolicy(this.state.newPassword, this.state.repeatPassword, "") !== "" ||
                  this.state.oldPassword === this.state.newPassword}>
                Ok
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}

export default withTranslation()(Login)
