import React, {Suspense, lazy} from 'react'
import AlertTemplate from 'react-alert-template-basic'
import deLocale from 'date-fns/locale/de'

import LoginApp from './login'
import Notfound from './notfound'
import Logout from './logout'
import ReactDOM from 'react-dom'
// import Tasks from './tasks'
// import Travel from './travel'
// import TravelOverview from './travelOverview'
// import TravelView from './travelView'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Provider as AlertProvider } from 'react-alert'
import { Route, BrowserRouter, Routes, useLocation, Navigate } from 'react-router-dom'
import { PersonalDataContextProvider } from './components/PersonalDataContext'

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { loggedIn } from "./components/AuthHelperMethods"
import { hasPermission } from "./components/AuthHelperMethods"

// Upgrade to material-ui 5
import { theme } from './theme'

import "./App.css"

import './i18n'

// Loading all components lazily to speed up loading performance
const MainPage = lazy(() => import('./main'))
const App = lazy(() => import('./calendar/App'))
const Workingtime = lazy(() => import('./timetracking/renderWorkingTimeScreen'))
const TimesheetApp = lazy(() => import('./timesheet/timesheet'))
const DummyDashboard = lazy(() => import('./dashboards/DummyDashbaord'))
const FundedProject = lazy(() => import('./fundedProject'))
const CheckInOut = lazy(() => import('./checkInOut'))
const Contacts = lazy(() => import('./contacts'))
const Dummies = lazy(() => import('./dummies'))
const HumanResources = lazy(() => import('./humanResources'))
const Inventory = lazy(() => import('./Inventory/inventory'))
const Profile = lazy(() => import('./profile'))
const OrgChart = lazy(() => import('./orgChart'))
const TimesheetPublicApp = lazy(() => import('./timesheet/timesheetPublic'))
const PersonalDashboard = lazy(() => import('./personalDashboard/personalDashboard'))
const TimelineApp = lazy(() => import('./milestones/timeline'))

/**
 * This contains the site structure with the links
 * @returns the route for given link
 */

const configFrontend = require('./config/config_front')

function Root() {
  return (
    <Suspense fallback={<h3>Loading...</h3>}>
      <AlertProvider template={AlertTemplate} position='bottom center' timeout={10000}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
              <BrowserRouter>
                <PersonalDataContextProvider>
                  <div>
                    <Routes>
                      <Route exact path='/' element={<LoginApp />}/>
                      <Route exact path='logout' element={<Logout />}/>
                      {configFrontend.calendarView &&(<Route path='app' element={<RequireAuth><App /></RequireAuth>}/>)}
                      <Route path='main' element={<RequireAuth><MainPage /></RequireAuth>}/>
                      {configFrontend.workingTimeView &&(
                        <Route path='workingtime' element={<RequireAuth><Workingtime /></RequireAuth>}/>)}
                      {configFrontend.timesheetView &&(
                        <Route path='timesheet' element={<RequireAuth><TimesheetApp /></RequireAuth>} />)}
                      {configFrontend.timesheetView &&(
                        <Route path='timesheet_verification/:timesheetId' element={<TimesheetPublicApp />} />)}
                      {configFrontend.profileView &&(
                        <Route path='profile' element={<RequireAuth><Profile /></RequireAuth>}/>)}
                      {configFrontend.inventoryView &&(
                        <Route path='inventory' element={<RequireAuth><Inventory /></RequireAuth>}/>)}
                      {configFrontend.contactsView &&(
                        <Route path='contacts' element={<RequireAuth><Contacts /></RequireAuth>}/>)}
                      {configFrontend.dummiesView &&(
                        <Route path='dummies' element={<RequireAuth><Dummies /></RequireAuth>}/>)}
                      {configFrontend.fundedProjectsView &&(
                        <Route path='foerderung' element={<RequireAuth><FundedProject /></RequireAuth>}/>)}
                      {configFrontend.orgChartView &&(
                        <Route path='orgChart' element={<RequireAuth><OrgChart /></RequireAuth>}/>)}
                      <Route path='checkInOut' element={<RequireAuth><CheckInOut /></RequireAuth>}/>
                      {configFrontend.humanResourcesView &&(<Route path='humanResources' element={
                        <RequireAuth permission='admin'><HumanResources /></RequireAuth>
                      }/>)}
                      {configFrontend.dummiesView &&(
                        <Route path="dummyDashboard/:dummyId" element={<RequireAuth><DummyDashboard /></RequireAuth>} />
                      )}
                      <Route path="personalDashboard" element={<RequireAuth><PersonalDashboard /></RequireAuth>} />
                      {configFrontend.milestonesView &&(
                        <Route path="timeline" element={<RequireAuth><TimelineApp /></RequireAuth>} />)}
                      {/* <Route path='travel' element={<RequireAuth><Travel /></RequireAuth>}/>
                        <Route path='travelView' element={<RequireAuth><TravelView /></RequireAuth>}/>
                        <Route path='travelOverview' element={<RequireAuth><TravelOverview /></RequireAuth>}/>
                        <Route path='tasks' element={<RequireAuth><Tasks /></RequireAuth>}/> */}
                      <Route path='*' element={<Notfound />}/>
                    </Routes>
                  </div>
                </PersonalDataContextProvider>
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AlertProvider>
    </Suspense>
  )
}

ReactDOM.render(<Root />, document.querySelector('#root'))

/**
 * Required children parameter which is the target element
 * permission allows to specify an additional permission
 * should be left blank if not needed
 * @returns redirects to children or to / if not logged in or no permission
 */
function RequireAuth({ children, permission }) {

  const location = useLocation()

  if (permission !== undefined) {
    return hasPermission(permission) ? children
      : <Navigate
        to="/"
        replace
        state={{ path: location.pathname }}
      />
  }

  return loggedIn() === true
    ? children
    : <Navigate
      to="/"
      replace
      state={{ path: location.pathname }}
    />
}
