import { createTheme } from '@mui/material/styles'

const configFront = require('./config/config_front')


export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: configFront.primaryColor ? configFront.primaryColor : '#0F4DA8',
    },
    secondary: {
      main: configFront.secondaryColor ? configFront.secondaryColor: '#FFA100',
    },
    background: {
      default: configFront.backgroundColor ? configFront.backgroundColor : "rgba(15, 77, 168, 0.1)"
    },
    error: {
      main: '#AB0000',
    }
  },
  // With the upgrade to MUI V5 the outlined variant is the new default
  // To keep the existing layout the variant is set to 'standard'
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
})
