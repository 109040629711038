import React from "react"

const configFront = require('../config/config_front')

class Footer extends React.Component {
  getYear() {
    return new Date().getFullYear()
  }

  render() {
    return (
      <div style={{backgroundColor: 'white'}}>
        <footer>
          <div id="logo-footer">
            <span>
              <img src={require(`./assets/${configFront.logo}`)}
                style={{maxWidth: '10em', maxHeight: '7em'}} className="icon-footer" alt="logo" />
            </span><br></br>
            <span>
         &copy; {this.getYear()} <b>acs plus.</b> All Rights Reserved.
            </span>
          </div>
        </footer>
      </div>
    )
  }
}

export default Footer
