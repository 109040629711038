import * as React from 'react'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { IconButton } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

/**
 * Renders a fab with a questionamrk which displays the
 * message when clicked
 * @param {str} message that is displayed when clicking the icon
 * @param {dict} sx optional parameter which allows the set the sx value
 * @returns fab with a questionamrk
 */
function HelpIcon({message, size='small', sx=null}) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <IconButton
        aria-describedby={id}
        sx={(sx !== null) ? sx : {mb: 2, mr: 2}}
        size={size}
        color="secondary"
        aria-label="help"
        onClick={handleClick}>
        <HelpOutlineIcon
          fontSize="inherit"
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          style={{whiteSpace: 'pre-line'}}
          sx={{ p: 2 }}>{message}</Typography>
      </Popover>
    </>
  )
}

export {HelpIcon}
