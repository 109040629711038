module.exports = {
  // colorPalette
  primaryColor: null,
  secondaryColor: null,
  backgroundColor: null,

  // Navbar and Header view (true = show, false = hide)
  calendarView: true,
  workingTimeView: true,
  contactsView: true,
  profileView: true,
  dummiesView: true,
  fundedProjectsView: false,
  timesheetView: true,
  milestonesView: false,
  orgChartView: true,
  inventoryView: false,
  humanResourcesView: true,

  // The logo has to be stored in src/components/assets/
  logo: 'pay33_logo.png'

}
