import React, {useEffect} from 'react'
import Footer from "./components/Footer"
import Header from "./components/Header"
import Login from "./components/Login"
import { loggedIn } from './components/AuthHelperMethods'

import { hasPermission } from './components/AuthHelperMethods'
import { useNavigate } from "react-router-dom"

function LoginApp() {

  const navigate = useNavigate()

  const forwardFunction = () => {
    navigate(hasPermission('tablet') ? '/checkInOut' : '/main')
  }

  useEffect(() => {
    // console.log(loggedIn())
    if (loggedIn()) {
      navigate(hasPermission('tablet') ? '/checkInOut' : '/main')
    }
  }, [navigate])


  return (
    <div className="LoginApp">
      <Header />
      <div style={{marginTop: '5em', marginBottom: '4em'}}>
        <Login forwardFunction={forwardFunction} />
      </div>
      <Footer />
    </div>
  )

}

export default LoginApp
