// ToDO
//  159: Conditional coloring
/* eslint-disable prefer-const */
import CustomFormField from '../components/CustomFormField'

import React, { useState, useEffect } from "react"
import { Form } from 'react-bootstrap'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { socket } from "../components/Header"
import { getToken } from "../components/AuthHelperMethods"
import { useTranslation } from "react-i18next"

const TimeEstimationForm = ({ value, setValue, disabled, type, onSubmit, firstEstimationDue, finalEstimationDue }) => {
  const { t } = useTranslation()
  // Show day counter only if no time estimation provided in current month
  // Colour red if deadline passed and no time estimation provided and deadline is set
  const colorAndText = ((finalEstimationDue < 1 && type === 'final' && !disabled && finalEstimationDue!==null) ||
    (firstEstimationDue<1 && type==='first' && !disabled && firstEstimationDue!==null)) ?
    ['red', t('daysOverdue')] :
    [null, t('daysRemaining')]
  const finalEstimationLabel = (disabled || finalEstimationDue === null) ?
    t('finalEstimation') :
    `${t('finalEstimation')} (${Math.abs(finalEstimationDue)} ${colorAndText[1]})`
  const firstEstimationLabel = (disabled || firstEstimationDue === null) ?
    t('firstEstimation') :
    `${t('firstEstimation')} (${Math.abs(firstEstimationDue)} ${colorAndText[1]})`
  const label = (type === 'final') ?
    finalEstimationLabel :
    firstEstimationLabel

  const submit = (event) => {
    event.preventDefault()
    onSubmit(value)
  }

  return (
    <Form onSubmit={submit} style={{padding: '5px'}}>
      <CustomFormField value={value} setter={setValue} label={label}
        disabled={disabled} type='number' color={colorAndText[0]}/>
      { !disabled ? <Button variant="contained" color="primary" type='submit' style={{float: 'right'}}>
        {t('setWorkingTimeEstimation')}
      </Button> : <></> }
    </Form>
  )
}

const months = ['Januar', 'Februar', 'März', 'April',
  'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
function splitMonth(month) {
  if (typeof(month) == "object") {
    month = month[0]
  }
  if (month == null) { return null }
  const index = Number(month.match(/^((10)|(11)|\d)/g)[0])
  const year = Number(month.match(/(\d)+$/g)[0])
  return {month: months[index], index: index, year: year}
}

function getPreviousMonths(currentMonth) {
  const month = splitMonth(currentMonth)
  const pMonths = []
  for (let i = 0; i < 12; i++) {
    const year = month.year - ((month.index - i < 0) ? 1 : 0)
    const index = (12 + month.index - i) % 12
    const monthName = months[index]

    pMonths.push({month: `${index}-${year}`, monthName, year})
  }
  return pMonths
}

const MonthSelect = ({ prevMonths, selectedMonth, setSelectedMonth }) => {
  const { t } = useTranslation()

  return (
    <FormControl variant={'outlined'} style={{marginLeft: '20px'}}>
      <InputLabel id="month-select-label">{t('Month')}</InputLabel>
      <Select
        labelId="month-select-label"
        id="month-select"
        value={selectedMonth}
        label={t('Month')}
        onChange={(event) => { setSelectedMonth(event.target.value) }}
      >
        {prevMonths.map((m) =>
          <MenuItem key={m.month} value={m.month}>{m.monthName} {m.year}</MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

export default function WorkingTimeEstimation() {
  const { t } = useTranslation()
  const [student, setStudent] = useState(false)
  const [originalStatus, setOriginalStatus] = useState({
    first: null,
    final: null
  })
  const [status, setStatus] = useState({
    first: null,
    final: null
  })
  const [currentMonth, setCurrentMonth] = useState(null)
  const [selectedMonth, setSelectedMonth] = useState('')
  const [ prevMonths, setPrevMonths ] = useState([])
  const [firstEstimationDeadline, setFirstEstimationDeadline] = useState(new Date(1970, 1, 1))
  const [finalEstimationDeadline, setFinalEstimationDeadline] = useState(new Date(1970, 1, 1))

  function getTimeEstimation(givenMonths) {
    if (!givenMonths) {
      socket.emit('getWorkingTimeEstimation', getToken(), (estimation, month) => {
        setOriginalStatus(estimation)
        setStatus(estimation)
        setSelectedMonth(month)
        setCurrentMonth(month)
        setPrevMonths(getPreviousMonths(month))
        socket.emit('getDeadlines', getToken(), month, ({first, final}) => {
          setFinalEstimationDeadline(new Date(final))
          setFirstEstimationDeadline(new Date(first))
        })
      })
    } else {
      socket.emit('getWorkingTimeEstimationForMonth', getToken(), givenMonths, (estimation) => {
        setOriginalStatus(estimation)
        setStatus(estimation)
      })
    }
  }

  function setTimeEstimation(value, type) {
    socket.emit('setWorkingTimeEstimation', getToken(), value, type, selectedMonth, (result) => {
      if (!result.success) {
        alert(result.message)
      } else {
        getTimeEstimation()
      }
    })
  }

  function changeSelectedMonth(month) {
    setOriginalStatus({first: null, final: null})
    setStatus({first: null, final: null})
    setSelectedMonth(month)
    getTimeEstimation(month)
  }

  useEffect(() => {
    getTimeEstimation()
    socket.emit('amIAStudent', getToken(), (result) => {setStudent(result)})
  }, []) // eslint-disable-line

  function renderTimeEstimationFormForType(type) {
    const currentDate = new Date()
    const firstEstimationDue = (firstEstimationDeadline.getFullYear() === 1970) ?
      null :
      firstEstimationDeadline.getDate() - currentDate.getDate()
    const finalEstimationDue = (finalEstimationDeadline.getFullYear() === 1970) ?
      null :
      finalEstimationDeadline.getDate() - currentDate.getDate()
    const isDifferentMonthSelected = (parseInt(selectedMonth.split('-')[0]) !== currentDate.getMonth())

    return (
      <ListItem key={type}>
        <div style={{width: '100%'}}>
          <TimeEstimationForm
            type={type}
            disabled={originalStatus[type] != null || isDifferentMonthSelected}
            value={(status[type]) ? status[type].numberOfHours : ''}
            setValue={(v) => {setStatus({...status, [type]: v})}}
            onSubmit={() => {setTimeEstimation(status[type], type)}}
            firstEstimationDue={firstEstimationDue}
            finalEstimationDue={finalEstimationDue}/>
        </div>
      </ListItem>
    )
  }

  if (currentMonth && student) {
    return (
      <Card>
        <CardHeader title={t('setWorkingTimeEstimation')}/>
        <CardContent>
          <MonthSelect
            prevMonths={prevMonths}
            selectedMonth={selectedMonth}
            setSelectedMonth={changeSelectedMonth}/>
          <List dense={true}>
            {renderTimeEstimationFormForType('first')}
            {originalStatus.first ? renderTimeEstimationFormForType('final') : <></>}
          </List>
        </CardContent>
      </Card>
    )
  } else {
    return <div></div>
  }
}

export { months, splitMonth, getPreviousMonths, MonthSelect }
