/**
 * Handles the German translation for the PDF print.
 * This is deprecated. The translation are now in the public folder
 */
const germanTranslation = {
  // Deprecated
  Address: 'Adresse',
  BankAccount: 'Kontoverbindung',
  Contract: 'Vertrag',
  ContractConditionSet: 'Vertragskonditionen',
  CoreData: 'Stammdaten',
  HealthInsurance: 'Krankenkasse',
  HealthInsurances: 'Krankenkassen',
  healthInsuranceId: 'Zuordnung Krankenkasse',
  PersonalId: 'Personal-ID',
  TaxData: 'Steuerdaten',
  WorkingTimeEstimation: 'Zeitabschätzung',

  authorizationInquiry: 'Nachfrage',
  authorizationStatus: 'Bestätigungsstatus',
  bankName: 'Name des Geldinstituts',
  bic: 'BIC',
  birthDate: 'Geburtsdatum',
  childAllowance: 'Kinderfreibetrag in €',
  city: 'Stadt',
  confession: 'Konfession',
  contractBegin: 'Vertragsbeginn',
  contractConditionBegin: 'Beginn dieser Vertragsbedingungen',
  contractConditionEnd: 'Ende dieser Vertragsbedingungen',
  contractEnd: 'Vertragsende',
  email: 'E-Mail',
  employee: 'Mitarbeiter/in',
  employeeSince: 'angestellt seit',
  employeeStatus: 'Angestelltenstatus',
  familyStatus: 'Familienstand',
  firstName: 'Vorname',
  healthInsuranceName: 'Name der Krankenkasse',
  hourlyWage: 'Stundenlohn in €',
  houseNumber: 'Hausnummer',
  iban: 'IBAN',
  intendedHoursPerWeek: 'erwartete Stunden pro Woche',
  lastName: 'Nachname',
  maxHoursPerWeek: 'maximal Stunden pro Woche',
  monthlyGrossSalary: 'Monatsgehalt brutto in €',
  numberOfHolidays: 'Anzahl Urlaubstage',
  numberOfChildren: 'Anzahl Kinder',
  password: 'Passwort (> 7 Zeichen)',
  personalId: 'Personal-ID',
  postcode: 'Postleitzahl',
  repeatPassword: 'Passwort wiederholen',
  socialInsuranceNumber: 'Sozialversicherungsnummer',
  street: 'Straße',
  taxBracket: 'Lohnsteuerklasse',
  taxId: 'Persönliche ID-Nummer (Steuer-ID)',
  validFrom: 'gültig ab',
  validTo: 'gültig bis',
  workingTime: 'Arbeitszeit in % von 40h-Woche',
  yearlyGrossSalary: 'Jahresgehalt brutto in €',
  ChangeAccess: 'Zugang ändern',
  Permissions: 'Rechte',
  ChangeRequests: 'Änderungsanfragen',
  CreateUser: 'Mitarbeiter/in anlegen',
  AccessCardNumbers: 'Zutrittskartennummern',
  Holidays: 'Urlaub',
  startDate: 'Startdatum',
  endDate: 'Enddatum'
}

const domains = {
  authorizationStatus: ['ausstehend', 'bestätigt', 'nachgefragt'],
  confession: ['römisch katholisch', 'evangelisch', 'islamische Konfession',
    'hinduistische Konfession', 'andere/keine'],
  employeeStatus: ['Praktikant (105)', 'Werksstudent (106)', 'Angestellt (101)', 'sozialversicherungsfrei (999)'],
  familyStatus: ['ledig', 'verheiratet', 'geschieden', 'anderer'],
  healthInsuranceName: ['Techniker Krankenkasse', 'AOK Nordost in Berlin', 'KKH', 'Big direkt gesund',
    'BARMER', 'HEK', 'AOK Niedersachsen', 'AOK PLUS'],
  taxBracket: ['I', 'II', 'III', 'IV', 'V', 'VI'],
  timesheetIssueStatus: ['offen', 'geschlossen', 'gelöst', 'bearbeitet'],
}

// This list contains all notifcation concerns which are used
const notificationConcerns = ['security', 'inquiries', 'pending', 'time-estimation',
  'deadlines', 'timesheet', 'vacationRequests', 'workingTime', 'information']

module.exports = { germanTranslation, domains, notificationConcerns }
