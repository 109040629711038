/**
 * @param {The password string entered in the UI text field} password
 * @param {The repeated password string entered in the UI text field} passwordRepeated
 * @param {The string to be returned if validation was successful} successMessage
 * @returns The translation string variable depending on success
 */
function CheckPasswordPolicy(password, passwordRepeated, successMessage) {
  if (password?.length < 8) {
    return "passwordTooShort"
  }
  if (password !== passwordRepeated) {
    return "repeatedPasswordWrong"
  }
  if (! /[a-z]/.test(password)) {
    return "passwordwithoutlowerCase"
  }
  if (! /[A-Z]/.test(password)) {
    return "passwordWithoutUppercase"
  }
  if (! /[0-1]/.test(password)) {
    return "passwordWithoutNumbers"
  }
  if (
    //eslint-disable-next-line max-len
    !/[,, ;, ., :, -, _, <, >, |, #, ', ~, !, §, $, %, &, (, ), [, \], {, }, +, *, /, €, @, ", =, ?, \\, `, ´, ^, °]/.test(
      password
    )
  ) {
    return "passwordWithoutSpecialCharacters"
  }
  return successMessage
}

export default CheckPasswordPolicy
