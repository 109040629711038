import React from 'react'
import { useState, useEffect, createContext } from 'react'
import AuthHelperMethods from './AuthHelperMethods'
import NotificationProvider from './NotificationProvider'
import { socket } from './Header'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

import SnackbarContent from '@mui/material/SnackbarContent'
import IconButton from '@mui/material/IconButton'
import { Close, ChevronRight } from '@mui/icons-material'
import { Helmet } from 'react-helmet'

import sound from '../media/soft_notification.mp3'
import logo from './assets/logo.png'

const notificationSound = new Audio(sound)
const applicationBaseTitle = 'acs plus sekretaer'
const PersonalDataContext = createContext()
const Auth = new AuthHelperMethods()
const notificationProvider = new NotificationProvider()

const notificationIdToKey = new Map()

function createDesktopNotification(msg, location, id, navigate, concern, refreshNotifications) {
  const notification = new Notification("Sekretaer", {icon: logo, body: msg, tag: id})

  notification.onclick = () => {
    navigate(location)
    notification.close()

    if (concern === "information") {
      socket.emit("dequeueNotification", Auth.getToken(), id, (result) => {})
      refreshNotifications()
    }
  }

  notification.onshow = () => notificationSound.play()
  notificationIdToKey.set(id, notification)
}

function createNotistackNotification(msg, location, id, navigate, closeSnackbar, enqueueSnackbar) {
  const action = (key) => (
    <React.Fragment>
      <IconButton
        style={{padding: '2px'}}
        aria-label="close"
        color="primary"
        onClick={() => {
          navigate(location, {state: id})
          closeSnackbar(key)
        }}
        size="large">
        <ChevronRight/>
      </IconButton>
      <IconButton
        style={{padding: '2px'}}
        aria-label="close"
        color="inherit"
        onClick={() => {
          closeSnackbar(key)
        }}
        size="large">
        <Close/>
      </IconButton>
    </React.Fragment>
  )

  // const action = key => (
  //   <>
  //     <button onClick={() => { navigate(location); closeSnackbar(key) }}>
  //       Go to
  //     </button>
  //     <button onClick={() => { closeSnackbar(key) }}>
  //       Dismiss
  //     </button>
  //   </>
  // )

  const snackbarKey = enqueueSnackbar(msg, {
    // action
    content: (key) => (
      <NotificationSnackbar id={key} message={msg} action={action} />
    )
  })
  notificationIdToKey.set(id, snackbarKey)
  notificationSound.play()
}

function PersonalDataContextProvider(props) {
  const [authenticated, setAuthenticated] = useState(false)
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const navigate = useNavigate()
  const [message, setMessage] = useState('')
  const [notificationList, setNotificationList] = useState(notificationProvider.getNotifications())

  notificationProvider.setEnqueueFunction((msg, location, id, concern) => {
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        createDesktopNotification(msg, location, id, navigate, concern, refreshNotifications)
      } else {
        createNotistackNotification(msg, location, id, navigate, closeSnackbar, enqueueSnackbar)
      }
    })
  })

  notificationProvider.setDismissFunction((id) => {
    Notification.requestPermission(function (permission) {
      if (permission === 'granted') {
        notificationIdToKey.get(id).close()
        notificationIdToKey.delete(id)
      } else {
        closeSnackbar(notificationIdToKey.get(id))
        notificationIdToKey.delete(id)
      }
    })
  })

  function clearNotifications() {
    notificationProvider.clearNotifications()
  }

  function refreshNotifications() {
    notificationProvider.refreshAll().then(() => {
      setNotificationList(notificationProvider.getNotifications())
    })
  }

  function refreshNotificationsForConcern(concern) {
    notificationProvider.refreshConcern(concern).then(() => {
      setNotificationList(notificationProvider.getNotifications())
    })
  }

  function onLogin() {
    const token = Auth.getToken()

    socket.emit('registerSocketForExpirationNotification', token, (msg) => {})

    if (token !== null) {
      socket.emit('amIPersonalDataLoggedIn', token, (result) => {
        if (result) {
          setAuthenticated(result)
        }
      })
    }

    socket.on('forceReauthentication', (msg) => {
      setAuthenticated(false)
      setMessage(msg)
    })

    refreshNotifications()

    socket.on('notificationRequest', (concern) => {
      refreshNotificationsForConcern(concern)
    })
  }

  useEffect(() => {
    if (Auth.loggedIn()) {
      onLogin()
    }
  }, []) // eslint-disable-line

  return (
    <PersonalDataContext.Provider value={{
      authenticated,
      setAuthenticated,
      message,
      setMessage,
      notificationList,
      notificationProvider,
      refreshNotifications,
      clearNotifications,
      onLogin }}>
      <Helmet>
        <title>
          {(notificationProvider.getNumberOfNotifications() > 0) ?
            `(${notificationProvider.getNumberOfNotifications()}) - ` : ''}
          {applicationBaseTitle}
        </title>
      </Helmet>

      {props.children}

    </PersonalDataContext.Provider>
  )
}

// eslint-disable-next-line react/display-name
const NotificationSnackbar = React.forwardRef((props, ref) => {
  return (
    <SnackbarContent
      ref={ref}
      message={props.message}
      action={props.action(props.id)}
      style={{width: 400}}/>
  )
})

function NotistackIntegration(props) {
  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}>
      <PersonalDataContextProvider>
        {props.children}
      </PersonalDataContextProvider>
    </SnackbarProvider>
  )
}

export { PersonalDataContext, NotistackIntegration as PersonalDataContextProvider, notificationProvider }
