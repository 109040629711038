/* eslint-disable curly */
import { socket } from './Header'
import { notificationConcerns } from '../frontBackEndShared/frontBackEndShared'
import { splitMonth } from '../timetracking/studentWorkingTimeEstimation'
import AuthHelperMethods from './AuthHelperMethods'

import { GenerateVacationRequestNotification,
  GenerateTimesheetNotification } from './notificationTextGenerator'

import { t } from 'i18next'

const locations = {
  security: { pathname: '/humanResources', state: { section: 'OrgChart' } },
  inquiries: { pathname: '/profile', state: { section: 'Address' } },
  pending: { pathname: '/humanResources', state: { section: 'ChangeRequests' } },
  deadlines: { pathname: '/humanResources', state: { section: 'WorkingTimeEstimation' } },
  'time-estimation': { pathname: '/workingtime' },
  timesheet: { pathname: '/timesheet' },
  vacationRequests: { pathname: '/profile', state: { section: 'Holidays' } },
  workingTime: { pathname: '/workingtime' },
  information: { pathname: '/profile', state: { section: 'Contract' } },
}


function getLocationForNotification(notification) {
  if (notification.concern === 'inquiries')
    return {pathname: '/profile', state: { section: notification.message }}
  else
    return locations[notification.concern]
}

class Notification {
  constructor(id, concern, message, location, basedOnPermission, dueDate) {
    this.id = id
    this.concern = concern
    this.message = message
    this.location = location
    this.basedOnPermission = basedOnPermission
    this.dueDate = dueDate
  }
}

class NotificationProvider {
  constructor() {
    this.clearNotifications()
    this.enqueueFunction = () => {}
    this.dismissFunction = () => {}
  }
  Auth = new AuthHelperMethods()

  clearNotifications() {
    this.concernId = {}
    for (const concern of notificationConcerns) {
      this[concern] = []
      this.concernId[concern] = 0
    }
  }

  setEnqueueFunction(callback) {
    this.enqueueFunction = callback
  }
  setDismissFunction(callback) {
    this.dismissFunction = callback
  }

  getNotifications() {
    return {
      security: this.security,
      inquiries: this.inquiries,
      pending: this.pending,
      deadlines: this.deadlines,
      timeEstimation: this['time-estimation'],
      timesheet: this.timesheet,
      vacationRequests: this.vacationRequests,
      workingTime: this.workingTime,
      information: this.information
    }
  }

  getNumberOfNotifications() {
    let n = 0
    for (const concern of notificationConcerns)
      n += this[concern].length
    return n
  }

  refreshDiffs(concern, newNotifications) {
    const oldIds = new Set(this[concern].map(obj => obj.id))
    const newIds = new Set(newNotifications.map(obj => obj.id))
    const addedIds = new Set(newNotifications.map(obj => obj.id).filter(x => !oldIds.has(x)))
    const disappearedIds = new Set(this[concern].map(obj => obj.id).filter(x => !newIds.has(x)))

    if (addedIds.size > 0) {
      let getMessage = () => 'Dummy message'
      if (concern === 'security') {
        getMessage = (notification) =>
          `${t(notification.message.firstName)} ${t(notification.message.lastName)} under attack`
      } else if (concern === 'pending')
        getMessage = (notification) =>
          `${t("NewPersonnelDataChange")} ${t(notification.message)}.
            ${t("PleaseConfirmOrAsk")}.`
      else if (concern === 'inquiries')
        getMessage = (notification) => `${t("NewDemandIn")} ${t(notification.message)} `
      else if (concern === 'time-estimation') {
        getMessage = (notification) => {
          const monthName = splitMonth(notification.message.month).month
          const dueDate = new Date(notification.dueDate)
          return t("ReminderFor") + ' ' +
            `${(notification.message.type === 'first') ? t("First") : t("Final")} ` +
            `${t("TimeEstimation")} ${t("for")} ${monthName}. ${t("DueBy")} ${dueDate.toLocaleDateString()}`
        }
      } else if (concern === 'deadlines') {
        getMessage = (notification) => {
          const monthName = splitMonth(notification.message.month).month
          const dueDate = new Date(notification.dueDate)
          return t("DeadlineReminder") +
            `${(notification.message.type === 'first') ? t("First") : t("Final")} ` +
            `${t("TimeEstimation")} ${monthName}. ${t("DueBy")} ${dueDate.toLocaleDateString()}`
        }
      } else if (concern === 'timesheet') {
        getMessage = (notification) => {
          return GenerateTimesheetNotification(notification, t)
        }
      } else if (concern === 'vacationRequests') {
        getMessage = (notification) => {
          return GenerateVacationRequestNotification(notification, t)
        }
      } else if (concern === 'workingTime') {
        getMessage= (notification) => {
          return `${t("AutoLogout")}`
        }
      } else if (concern === 'information') {
        getMessage= (notification) => {
          return `${t(notification.message)}`
        }
      }

      for (const notification of newNotifications) {
        if (addedIds.has(notification.id)) {
          this.enqueueFunction(getMessage(notification), notification.location, notification.id, notification.concern)
        }
      }
    }

    disappearedIds.forEach((id) => this.dismissFunction(id))
  }

  refreshAll() {
    return Promise.all(
      notificationConcerns.map((concern) => this.refreshConcern(concern))
    )
  }

  refreshConcern(concern) {
    return new Promise((resolve, reject) => {
      if (concern === 'pending') {
        const token = this.Auth.getDecodedToken()
        if (!token.permissions.includes('humanResources')) {
          resolve()
          return
        }
      }

      socket.emit('notifications/' + concern, this.Auth.getToken(), (result) => {
        const notifications = []

        for (const n of result) {
          notifications.push(new Notification(
            n.id,
            n.concern,
            n.message,
            getLocationForNotification(n),
            n.basedOnPermission,
            n.dueDate
          ))
        }

        this.refreshDiffs(concern, notifications)
        this[concern] = notifications
        this.concernId[concern]++
        resolve()
      })
    })
  }
}

export default NotificationProvider
