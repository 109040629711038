import moment from 'moment'
import React from 'react'
import { Form, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const style = {fontSize: '95%'}

const CustomFormField = ({ label, value, setter, optional, type, domain, color=null, ...otherProps }) => {
  const {t} = useTranslation()
  return (
    <Form.Group as={Form.Row}>
      <Col>
        <Form.Label style={{color: color}}>
          {label}
          <span style={{color: 'red', fontWeight: 'bold'}}>{!optional ? ' *' : ''}</span>
        </Form.Label>
      </Col>
      <Col>
        { type === 'domain' ? (
          <Form.Control style={style} required={!optional} as='select' {...otherProps}
            placeholder={t("PleaseSelect")}
            value={value}
            onChange={event => { setter(event.target.value) }}>

            <option disabled>{t("PleaseSelect")}</option>
            { domain.map(element => (<option key={element}>{element}</option>)) }

          </Form.Control>
        ) : (
          <Form.Control style={style} required={!optional} type={type} {...otherProps}
            value={value && type === 'date' ? moment(value).format('YYYY-MM-DD') : value}
            onChange={event => { setter(event.target.value) }}/>
        )}
      </Col>
    </Form.Group>
  )
}

const RequiredFieldsHint = ({ styl }) => {
  const {t} = useTranslation()
  return (
    <div style={{textAlign: 'right', ...styl}}>
      <span style={{fontWeight: 'bold', color: 'grey'}}>
        <span style={{color: 'red'}}>{' * '}</span> {t("RequiredFields")}
      </span>
    </div>
  )
}

export default CustomFormField
export { RequiredFieldsHint }
