/**
 * Handles the creation of a string for the vacation requests
 * @param {object} notification
 * @param {t} t for translation
 * @returns
 */
function GenerateVacationRequestNotification(notification, t) {
  if (notification.id === 'vacationRequestConfirm') {
    return `${t("YourHolidayFrom")} ${notification.message}. ${t("CanBeConfirmed")}`
  } else {
    return `${t("ReleaseOfHolidayFrom")} ${notification.message}.`
  }
}

function GenerateTimesheetNotification(notification, t) {
  if (notification.id === 'timesheetReview') {
    //('timesheetNotificationReview', {messageCount: notification.message})
    return t('timesheetNotificationReview', {messageCount: notification.message})
  } else {
    return `${t("ThereAre")} ${notification.message} ${t("NotesOnTimeSheetsBefore")}`
  }
}

export {GenerateVacationRequestNotification, GenerateTimesheetNotification}
