import React from "react"

import socketIOClient from "socket.io-client"

const config = require('../config/config')

const socket = socketIOClient(config.endpoint, {secure: true}, {transports: config.transports})

class Header extends React.Component {
  /* Creating a Socket client and exporting it at the end to be used across the components */

  render () {
    return (<div><header/></div>)
  }
}

export default Header
export { socket }
