import React, { useContext, useEffect } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { logout } from './components/AuthHelperMethods'
import { PersonalDataContext } from './components/PersonalDataContext'
import { useNavigate } from "react-router-dom"


export default function Logout() {
  const { clearNotifications } = useContext(PersonalDataContext)
  const navigate = useNavigate()

  useEffect(() => {
    clearNotifications()
    logout()
    navigate('/')
  }, [clearNotifications, navigate])


  return (
    <div className="Logout">
      <Header />
      <main>
      </main>
      <Footer />
    </div>
  )
}
