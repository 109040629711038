import React from 'react'
import {
  Card
} from 'react-bootstrap'
import Button from '@mui/material/Button'
import { Link as RouterLink } from "react-router-dom"
import { Link } from '@mui/material'


const Notfound = () =>
  <Card>
    <Card.Body className="text-center">
      <div className="spacer-90"></div>
      <div className="spacer-90"></div>
      <h1>404: Page not found</h1>
      <Link to={"/"} component={RouterLink}>
        <Button variant="contained" color="primary" type='submit'>
            Zurück zum Login
        </Button>
      </Link>
      <div className="spacer-90"></div>
    </Card.Body>
  </Card>
export default Notfound
